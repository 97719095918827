<template >
  <div>
    <div class="row">
      <div class="col-md-12">
        <v-card v-if="selected == null" style="margin-bottom: 30px">
          <v-card-title>
            Orders Home
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            @click:row="clicked"
            :headers="headers"
            :items="homes"
            :search="search"
            style="cursor: pointer;"
          ></v-data-table>
        </v-card>

        <v-card v-if="selected == null">
          <v-card-title>
            Orders Business
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search2"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            @click:row="clicked"
            :headers="headers"
            :items="offices"
            :search="search2"
            style="cursor: pointer;"
          ></v-data-table>
        </v-card>

        <div v-if="selected">
          <b-card class="mt-3" header="Info Order">
            <b-form>
              <b-row>
                <b-col>
                  <b-form-group id="input-group-2" label="ID:" label-for="i1">
                    <b-form-input id="i1" v-model="selected.order_id" disabled></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group id="input-group-2" label="Type Cleaning:" label-for="i2">
                    <b-form-input id="i2" v-model="selected.type" disabled></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group id="input-group-2" label="Date Cleaning:" label-for="i3">
                    <b-form-input id="i3" v-model="selected.date" disabled></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group id="input-group-2" label="Value:" label-for="i5">
                    <b-form-input id="i5" v-model="selected.value" disabled></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group id="input-group-2" label="Value Ind. Contractor:" label-for="i6">
                    <b-form-input id="i6" v-model="selected.value_final" disabled></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-card>

          <b-card class="mt-3" header="Place Info">
            <b-form>
              <b-row>
                <b-col>
                  <b-form-group id="input-group-2" label="Place Name:" label-for="i1">
                    <b-form-input id="i1" v-model="selected.place_name" disabled></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group id="input-group-2" label="Address:" label-for="i2">
                    <b-form-input id="i2" v-model="selected.place_address" disabled></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group id="input-group-2" label="City:" label-for="i3">
                    <b-form-input id="i3" v-model="selected.place_city" disabled></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group id="input-group-2" label="Zipcode:" label-for="i5">
                    <b-form-input id="i5" v-model="selected.place_address" disabled></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-card>

          <b-card class="mt-3" header="Customer Info">
            <b-form>
              <b-row>
                <b-col>
                  <b-form-group id="input-group-2" label="Name:" label-for="i1">
                    <b-form-input id="i1" v-model="selected.customer_name" disabled></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group id="input-group-2" label="Email:" label-for="i2">
                    <b-form-input id="i2" v-model="selected.customer_email" disabled></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group id="input-group-2" label="Phone:" label-for="i3">
                    <b-form-input id="i3" v-model="selected.customer_phone" disabled></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group id="input-group-2" label="Address:" label-for="i5">
                    <b-form-input id="i5" v-model="selected.customer_address" disabled></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-button
                type="button"
                @click="selected = null"
                variant="danger"
                style="margin-right: 20px;"
              >Back</b-button>
            </b-form>
          </b-card>

          <!-- <b-card class="mt-3" header="Ind. Con. Info">
            <b-form>
              <b-form-group id="input-group-2" label="Name:" label-for="i1">
                <b-form-input id="i1" v-model="selected.customer_name" disabled></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-2" label="Email:" label-for="i2">
                <b-form-input id="i2" v-model="selected.customer_email" disabled></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-2" label="Phone:" label-for="i3">
                <b-form-input id="i3" v-model="selected.customer_phone" disabled></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-2" label="Address:" label-for="i5">
                <b-form-input id="i5" v-model="selected.customer_address" disabled></b-form-input>
              </b-form-group>

              <b-button
                type="button"
                @click="selected = null"
                variant="danger"
                style="margin-right: 20px;"
              >Return</b-button>
            </b-form>
          </b-card>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "order-list",
  methods: {
    clicked(value) {
      this.selected = value;
      console.log(this.selected);
    },

    onSubmit(evt) {
      evt.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  },
  data() {
    return {
      fields: ["name", "city", "address", "type"],
      form: {
        email: "",
        name: "",
        registrationDate: "",
        food: null,
        checked: []
      },
      search: "",
      search2: "",
      selected: null,
      headers: [
        { text: "Order ID", value: "order_id" },
        { text: "Customer", value: "customer_name" },
        { text: "City", value: "place_city" },
        { text: "Date Cleaning", value: "date" },
        { text: "Cleaning Type", value: "type" },
        { text: "Value", value: "value" },
        { text: "Status", value: "status" }
      ],
      homes: [
        {
          id: "",
          name: ""
        }
      ],
      offices: [
        {
          id: "",
          name: ""
        }
      ]
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Tables", route: "simple-tables" },
      { title: "Data Tables" }
    ]);

    fetch("https://cleancity.krueltech.com/dev/api/api.php?action=readOrders")
      .then(res => res.json())
      .then(data => {
        this.homes = data.orders.homes;
        this.offices = data.orders.office;
      })
      .catch(console.log);
  }
};
</script>
